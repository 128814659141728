import React from "react"
import { graphql, Link } from "gatsby"
// import { format } from "date-fns"

import IndexLayout from "../layouts/indexLayout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const articles = data.articles.edges
  const resources = data.resources.edges
  return (
    <IndexLayout>
      <SEO title="Home" />
      <h4>Articles</h4>
      {articles &&
        articles.map((article) => {
          const { title, slug, intro } = article.node.frontmatter
          const { id } = article.node.parent

          return (
            <article key={id} className="u-mb+">
              <h3>
                <Link to={`${slug}`}>{title}</Link>
              </h3>
              <div className="entry-content">
                <p>{intro}</p>
                <p>
                  <Link to={`${slug}`}>Read Article ➝</Link>
                </p>
              </div>
            </article>
          )
        })}
      <hr />
      <article className="u-mb+">
        <h4>Resource</h4>
        <h3>
          <Link to="/privacy-experience-resources">Privacy UX Resources</Link>
        </h3>
        <div className="entry-content">
          <p>
            People-centred resources for product teams that want to design
            better privacy experiences.
          </p>
        </div>
      </article>
      {resources &&
        resources.map((article) => {
          const { title, slug, resource_promise, page_type } =
            article.node.frontmatter
          const { id } = article.node.parent

          return (
            <article key={id} className="u-mb+">
              <h4>{page_type}</h4>
              <h3>
                <Link to={`${slug}`}>{title}</Link>
              </h3>
              <div className="entry-content">
                <p>
                  {resource_promise.charAt(0).toUpperCase() +
                    resource_promise.slice(1)}
                  .
                </p>
              </div>
            </article>
          )
        })}
    </IndexLayout>
  )
}

export const query = graphql`
  {
    articles: allMdx(
      filter: { frontmatter: { slug: { ne: null }, intro: { ne: null } } }
      sort: { fields: frontmatter___articleDate, order: DESC }
    ) {
      edges {
        node {
          parent {
            ... on File {
              id
            }
          }
          frontmatter {
            slug
            title
            resource_promise
            page_type
            intro
            articleDate
          }
        }
      }
    }
    resources: allMdx(
      filter: {
        frontmatter: { slug: { ne: null }, resource_promise: { ne: null } }
      }
    ) {
      edges {
        node {
          parent {
            ... on File {
              id
            }
          }
          frontmatter {
            slug
            title
            resource_promise
            page_type
            intro
            articleDate
          }
        }
      }
    }
  }
`

export default IndexPage
